<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.dentists.index', query: {...this.$route.query}}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <h2>Info</h2>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <tbody class="simple-table">
                                <tr>
                                    <td>NPI</td>
                                    <td>{{ dentist.npi }}</td>
                                </tr>
                                <tr>
                                    <td>Provider Name</td>
                                    <td>{{ dentist.provider_name }}</td>
                                </tr>
                                <tr>
                                    <td>Last Updated</td>
                                    <td>{{ dentist.updated_at }}</td>
                                </tr>
                                <tr>
                                    <td>Certification Date</td>
                                    <td>{{ dentist.certification_date }}</td>
                                </tr>
                                <tr>
                                    <td>Enumeration Date</td>
                                    <td>{{ dentist.provider_enumeration_date }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h2>Authorized Official Information</h2>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <tbody class="simple-table">
                                <tr>
                                    <td>First name</td>
                                    <td>{{ dentist.auth_official_first_name }}</td>
                                </tr>
                                <tr>
                                    <td>Middle name</td>
                                    <td>{{ dentist.auth_official_middle_name }}</td>
                                </tr>
                                <tr>
                                    <td>Last name</td>
                                    <td>{{ dentist.auth_official_last_name }}</td>
                                </tr>
                                <tr>
                                    <td>Position</td>
                                    <td>{{ dentist.auth_official_position }}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{{ dentist.auth_official_phone }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                        <h2>Addresses</h2>
                    </v-col>
                    <v-col cols="12" md="6" v-for="address in dentist.addresses" :key="address.id">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left" colspan="2">{{titleAddress(address.purpose)}}</th>
                                </tr>
                                </thead>
                                <tbody class="simple-table">
                                <tr>
                                    <td>Address</td>
                                    <td>{{ address.address }}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{{ address.city }}</td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>{{ address.state }}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{{ address.country }}</td>
                                </tr>
                                <tr>
                                    <td>Fax</td>
                                    <td>{{ address.fax }}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{{ address.phone }}</td>
                                </tr>
                                <tr>
                                    <td>Postal</td>
                                    <td>{{ dentist.postal }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12">
                        <h2>Identifiers</h2>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Number</th>
                                    <th>Issuer</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in dentist.identifiers" :key="item.id">
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.identifier }}</td>
                                    <td>{{ item.issuer }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12">
                        <h2>Taxonomies</h2>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>State</th>
                                    <th>Primary Taxonomy</th>
                                    <th>License</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in dentist.taxonomies" :key="item.id">
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.state }}</td>
                                    <td>{{ item.primary ? 'Yes' : 'No' }}</td>
                                    <td>{{ item.license }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import Dentist from "../../../models/Dentist";

const PURPOSE_MAILING = 1;
const PURPOSE_LOCATION = 2;
export default {
    name: "Show",
    components: {AdminDashboardLayout},
    data() {
        return {
            dentist: {}
        }
    },
    methods: {
        titleAddress(purpose) {
            if(purpose === PURPOSE_MAILING){
                return 'Mailing Address'
            }
            if(purpose === PURPOSE_LOCATION){
                return 'Practice Address'
            }
            return '';
        }
    },
    async mounted() {
        const dentistId = this.$route.params.id
        this.dentist = await Dentist.find(dentistId)
    },
}
</script>

<style scoped>
.simple-table tr td:nth-child(2) {
    text-align: right;
}
</style>
